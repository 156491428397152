<template>
    <div class="container product-details mt-5">
      <div v-if="product" class="row">
        <div class="col-md-6">
          <div class="image-wrapper">
            <img :src="getImageUrl(product.image)" :alt="product.name" class="img-fluid" />
          </div>
        </div>
        <div class="col-md-6">
          <h1 class="mb-3">{{ product.name }}</h1>
          <h4 class="text-muted mb-3">{{ product.category }}</h4>
          <h3 class="text-success mb-3">${{ product.price }}</h3>
          <p>{{ product.description }}</p>
          
          <!-- Nutritional Information Accordion -->
          <div class="accordion" id="nutritionAccordion">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  Nutritional Information
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#nutritionAccordion">
                <div class="accordion-body">
                  <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Nutrient</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Calories</th>
                            <td>{{ product.calories }}</td>
                        </tr>
                        <tr>
                            <th scope="row">Total Fat</th>
                            <td>{{ product.total_fat }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Cholesterol</th>
                            <td>{{ product.cholesterol }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Sodium</th>
                            <td>{{ product.sodium }} (mg)</td>
                        </tr>
                        <tr>
                            <th scope="row">Total Carbs</th>
                            <td>{{ product.total_carbs }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Dietary Fiber</th>
                            <td>{{ product.dietary_fiber }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Total Sugar</th>
                            <td>{{ product.total_sugar }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Added Sugar</th>
                            <td>{{ product.added_sugar }} (g)</td>
                        </tr>
                        <tr>
                            <th scope="row">Protein</th>
                            <td>{{ product.protein }} (g)</td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          
          <router-link to="/store" class="btn mt-3 mb-3">Back to Store</router-link>
        </div>
      </div>
      <div v-else-if="isLoading">
        <p>Loading...</p>
      </div>
      <div v-else>
        <p>Product not found.</p>
        <router-link to="/store" class="btn">Back to Store</router-link>
      </div>
    </div>
  </template>
  
  <script>
  import { computed, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import {useMeta} from 'vue-meta'

  export default {
    setup() {
      const store = useStore();
      const route = useRoute();
      const productId = route.params.slug;
      const isLoading = ref(true);
  
      const product = computed(() => {
        return store.getters.products.find(p => p.slug === productId);
      });

      useMeta({
      title: `${product.value.name}`,
      htmlAttrs: {lang: 'en', amp: true}
    });
  
      onMounted(async () => {
        if (!product.value) {
          await store.dispatch('fetchProducts');
        }
        isLoading.value = false;
      });
  
      const getImageUrl = (imagePath) => {
        try {
            return require(`@/assets/images/${imagePath}`);
        } catch (error) {
            console.error('Error loading image:', imagePath, error);
            return `/img/jar-placeholder.svg`; // Provide a fallback URL or empty string
        }
        };
  
      return {
        product,
        isLoading,
        getImageUrl
      };
    }
  };
  </script>
  
  <style scoped>
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  </style>
  