<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `PastryPods | ${content}` : `PastryPods` }}</template>
  </metainfo>
  <site-header/>
  <router-view/>
  <site-footer/>
</template>

<script>
import SiteHeader from './components/SiteHeader.vue'
import SiteFooter from './components/SiteFooter.vue'
import {useMeta} from 'vue-meta'

export default {
  components: {
    SiteHeader,
    SiteFooter
  },
  setup () {
    useMeta({
      title: 'PastryPods',
      htmlAttrs: {lang: 'en', amp: true}
    })
  }
}
</script>

<style lang="scss">
#app {}
</style>
