<!-- src/components/Announcement.vue -->
<template>
    <div class="announcement-bar">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-9">
            <span>{{ message }}</span>
          </div>
          <div class="col-md-3 mt-3 mt-md-0">
            <router-link class="btn btn-alt btn-sm" :to="ctaLink">{{ ctaText }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SiteAnnouncement',
    props: {
      message: {
        type: String,
        required: true
      },
      ctaText: {
        type: String,
        required: true
      },
      ctaLink: {
        type: String,
        required: true
      }
    }
  };
  </script>