import { createStore } from "vuex";
import { parseCSV } from "@/utils/csvUtils";

const store = createStore({
    state: {
        products: []
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        }
    },
    actions: {
        async fetchProducts({commit}) {
            try {
                const products = await parseCSV('/products_data.csv');
                commit('setProducts', products);
            } catch (error) {
                console.error('Failed to load products:', error)
            }
        }
    },
    getters: {
        products: state => state.products
    }
});

export default store;