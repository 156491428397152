<template>
  <header>
    <div class="container">
      <div class="row align-items-center">
        <!-- Logo that is always visible in the header -->
        <div class="col-6">
          <div class="navbar-logo">
            <router-link class="navbar-brand" to="/">
              <img src="../assets/images/logo-black.svg" alt="PastryPods" width="200">
            </router-link>
          </div>
        </div>

        <div class="col-6">
          <!-- Regular Desktop Navbar -->
          <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <div class="container">
              <button 
                @click="toggleMenu"
                class="navbar-toggler"
                type="button"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse d-none d-lg-block">
                <ul class="navbar-nav ms-auto">
                  <li class="nav-item">
                    <router-link class="nav-link" to="/">Home</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/about">About</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/store">Store</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link" to="/contact">Contact</router-link>
                  </li>
                  <!-- Add any additional navigation items here -->
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <!-- Mobile Sidebar Navigation -->
    <div v-if="isMenuActive" class="overlay" @click="closeMenu" tabindex="0"></div> <!-- Overlay to block interaction with the rest of the site -->
    <nav 
      :class="['sidebar', { 'is-active': isMenuActive }]" 
      id="sidebarMenu" 
      aria-hidden="true"
      tabindex="-1"
    >
      <button 
        @click="closeMenu"
        class="close-sidebar"
        aria-label="Close navigation"
        tabindex="0"
      >&times;</button>
      <ul class="sidebar-nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/" @click="closeMenu">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/about" @click="closeMenu">About</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/store" @click="closeMenu">Store</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/contact" @click="closeMenu">Contact</router-link>
        </li>
        <!-- Add any additional navigation items here -->
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
  data() {
    return {
      isMenuActive: false
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
      document.getElementById('sidebarMenu').setAttribute('aria-hidden', !this.isMenuActive);
      if (this.isMenuActive) {
        document.body.style.overflow = 'hidden'; // Prevent scrolling of the background
      } else {
        document.body.style.overflow = ''; // Restore scrolling
      }
    },
    closeMenu() {
      this.isMenuActive = false;
      document.getElementById('sidebarMenu').setAttribute('aria-hidden', 'true');
      document.body.style.overflow = ''; // Restore scrolling
    }
  }
};
</script>

<style scoped>
.navbar-logo {
  margin: 20px 0;
}

/* Adjustments for mobile */
.navbar-toggler {
  margin-left: auto;
  border: none;
  background: none;
  font-size: 1.5rem;
}

/* Sidebar styles for mobile */
.sidebar {
  position: fixed;
  top: 0;
  right: 0; /* Open from the right */
  height: 100%;
  width: 250px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3); /* Shadow on the left side */
  transform: translateX(100%); /* Start hidden */
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.sidebar.is-active {
  transform: translateX(0); /* Slide in from the right */
}

.close-sidebar {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 10px;
}

.sidebar-nav {
  list-style: none;
  padding-left: 0;
}

.sidebar-nav .nav-item {
  margin-bottom: 1rem;
}

.sidebar-nav .nav-link {
  font-size: 1.2rem;
  color: #333;
  text-decoration: none;
  display: block;
  padding: 0.5rem 0;
}

.sidebar-nav .nav-link:hover {
  color: #007bff;
}

/* Overlay to make the rest of the site inaccessible when the sidebar is open */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1049; /* Just below the sidebar */
}

/* Hide mobile sidebar on larger screens */
@media (min-width: 992px) {
  .sidebar {
    display: none;
  }
}
</style>
