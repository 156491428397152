<template>
  <main id="mainContent" class="main-content">
     <!-- Announcement Component -->
     <SiteAnnouncement
      message="We are offering a 10% discount to all healthcare workers and first responders!"
      ctaText="Learn More"
      ctaLink="/#discount-details"
    />
    <section class="module module-hero dark">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img alt="" aria-hidden="true" src="../assets/images/hero-girl-image.svg">
          </div>
          <div class="col-md-6">
            <h1 class="subtitle">PastryPods</h1>
            <h2>Welcome to Pastry Paradise!</h2>
            <p>Explore a world of flavor delights!</p>
            <router-link class="btn btn-alt" to="/#newest-flavors" aria-label="Explore our newest flavors">Explore</router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="module module-default bg-color-neutral">
      <div class="divider"></div>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 img-wrapper">
            <img alt="" src="../assets/images/order-cake-jar.png">
          </div>
          <div class="col-md-6">
            <h2 class="subtitle">Order Online</h2>
            <h3 class="heading--h2">Crave, Click, Enjoy!</h3>
            <p>Be the first to know when we start our online orders.</p>
            <router-link class="btn" to="/#email-alerts" aria-label="Subscribe to our email alerts">Subscribe</router-link>
          </div>
        </div>
      </div>
    </section>
    <section id="newest-flavors" class="module module-cards bg-color-primary">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="module-header center">
              <h2>Meet Our Newest Flavors!</h2>
              
            </div>
          </div>
        </div>
        <div class="row align-items-center mb-4">

          <div v-for="product in filteredProducts" :key="product.id" class="col-md-6 col-lg-4 content-wrapper"> <!-- Column - start -->
            <router-link :to="{name: 'ProductDetails', params: {slug: product.slug}}">
              <div class="content-box"> <!-- Content-box - start -->
              <div class="content-header">
                <img alt="" :src="getImageUrl(product.image)">
              </div>
              <div class="content-body">
                <div class="text">
                  <h3>{{ product.name }}</h3>
                  <p>{{ product.description }}</p>
                </div>
              </div>
            </div><!-- Content-box - end -->
            </router-link>
          </div><!-- Column - end -->
          <div class="col-md-12 col-lg-4 content-wrapper">
            <div class="content text-center">
              <h3>Our Cake Jars</h3>
              <p>Discover a wide variety of cake jars delievered to your doorstep. Protein-packed, Vegan and Gluten-free flavors coming soon.</p>
              <router-link class="btn btn-alt" to="/store">View More</router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center">

          <div class="col-md-12 col-lg-4 content-wrapper d-none d-md-block">
            <div class="content text-center mb-4">
              <h3>Our Fudges</h3>
              <p>Treat Yo' Self to Sweet Bliss</p>
              <router-link class="btn btn-alt" to="/store">View More</router-link>
            </div>
          </div>
          <div v-for="product in fudges" :key="product.id" class="col-md-6 col-lg-4 content-wrapper"> <!-- Column - start -->
            <router-link :to="{name: 'ProductDetails', params: {slug: product.slug}}">
              <div class="content-box"> <!-- Content-box - start -->
              <div class="content-header">
                <img alt="" :src="getImageUrl(product.image)">
              </div>
              <div class="content-body">
                <div class="text">
                  <h3>{{ product.name }}</h3>
                  <p>{{ product.description }}</p>
                </div>
              </div>
            </div><!-- Content-box - end -->
            </router-link>
          </div><!-- Column - end -->
        </div>
      </div>
    </section>
    <section class="module module-default">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <h2>Meet Your New Snacking Pals</h2>
            <p>Collect adorable PastryPods Plushies</p>
            <div class="btn">Coming Soon</div>
          </div>
          <div class="col-md-6">
            <img class="float-right margin-top" alt="" aria-hidden="true" src="../assets/images/plushies.png">
          </div>
        </div>
      </div>
    </section>
    <section id="discount-details" class="module module-default">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-12">
            <p class="footnote">To qualify for the 10% discount, eligible healthcare workers must present valid identification at the time of purchase. This offer is exclusively available to individuals currently employed in the healthcare sector. We appreciate your dedication and commitment to the well-being of our community.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="email-alerts" class="section module module-subscribe">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="module-content">
              <div class="row justify-content-center justify-content-md-between align-items-center">
                <div class="col-md-4">
                  <h2>Stay in the Loop</h2>
                </div>
                <div class="col-md-4">
                  <p class="mb-md-0">Get exclusive updates now</p>
                </div>
                <div class="col-md-auto">
                  <router-link class="btn btn-alt" to="/contact" aria-label="Subscribe to our newsletter">Subscribe</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {useMeta} from 'vue-meta'
import {computed, onMounted} from 'vue';
import {useStore} from 'vuex';
import SiteAnnouncement from '@/components/SiteAnnouncement.vue';

export default {
  name: 'HomeView',
  components: {
    SiteAnnouncement
  },
  setup () {
    const store = useStore();

    onMounted(() => {
      store.dispatch('fetchProducts');
    });

    const products = computed(() => store.getters.products);

    const filteredProducts = computed(() => {
      return products.value.filter(product => product.category === 'Cake Pod' && product.image).slice(0, 2);
    });

    const fudges = computed(() => {
      return products.value.filter(product => product.category === 'Fudge').slice(0, 2);
    });

    useMeta({
      title: 'Home',
      htmlAttrs: {lang: 'en', amp: true}
    });

    const getImageUrl = (imagePath) => {
        try {
            return require(`@/assets/images/${imagePath}`);
        } catch (error) {
            console.error('Error loading image:', imagePath, error);
            return `/img/jar-placeholder.svg`; // Provide a fallback URL or empty string
        }
        };

    return {
      filteredProducts,
      fudges,
      getImageUrl
    }
  }
}
</script>
