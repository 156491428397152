<template>
    <footer>
      <div class="container">
        <div class="row justify-content-center align-items-end">
            <div class="col-12 footer-content">
                <div class="row">
                  <div class="col-12"><p class="center">&copy; 2024 PastryPods LLC | All Rights Reserved.</p></div>
                  <!-- Add any additional footer content here -->
                  <div class="col-12">
                    <ul class="social-media">
                      <li><a href="https://www.instagram.com/pastrypodsllc/" aria-label="View our Instagram" target="_blank"><img src="../assets/images/instagram.svg" alt="Instagram"></a></li>
                      <li><a href="https://www.facebook.com/profile.php?id=61556708278171" aria-label="View our Facebook" target="_blank"><img src="../assets/images/facebook.svg" alt="Facebook"></a></li>
                      <li><a href="https://twitter.com/PastryPodsLLC" aria-label="View our X" target="_blank"><img src="../assets/images/x.svg" alt="X"></a></li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'SiteFooter'
  };
  </script>
  
  <style scoped>
  /* Add your custom styling for the footer */
  </style>