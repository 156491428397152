import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import {createMetaManager} from 'vue-meta'
import { VueGtag } from 'vue-gtag'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// Create the Vue app
const app = createApp(App);

// Use the necessary plugins
app.use(i18n);
app.use(createMetaManager());
app.use(VueGtag, {
  appName: 'PastryPods',
  pageTrackerScreenviewEnabled: true,
  config: { id: 'G-Z53QHSLM93' },
});
app.use(router);
app.use(store);

// Mount the app
app.mount('#app');
